<template>
    <div id="id01" class="w3-modal w3-theme-l4 scrl-y" style="display: block;">
        <div class="w3-modal-content w3-card-2 w3-animate-zoom w3-round-large w3-light-gray" style="max-width:450px">
            <div class="w3-center">
                <img src="assets/images/admin.png" alt="Avatar" style="width:30%" class="w3-circle w3-margin-top">
            </div>
            <form class="w3-container" v-on:submit.prevent="setLogin" autocomplete="off">
                <div class="w3-row w3-section">
                    <div class="w3-col" style="width:50px">
                        <i class="w3-xxlarge fa fa-user"></i>
                    </div>
                    <div class="w3-rest">
                        <input class="w3-input w3-round-xlarge w3-margin-bottom" v-model="rec.username" id="username" placeholder="Username or Email" type="text" ref="email" required>
                    </div>
                </div>
                <div class="w3-row w3-section">
                    <div class="w3-col" style="width:50px">
                        <i class="w3-xxlarge fa fa-lock"></i>
                    </div>
                    <div class="w3-rest" style="position: relative;">
                        <input class="w3-input w3-round-xlarge w3-margin-bottom" v-model="rec.password" placeholder="Password" :type="lihat?'text':'password'" required>
                        <button v-on:click="lihat=!lihat" type="button" class="w3-button w3-text-black w3-round-xlarge" style="position: absolute;top: 0;right: 0;"><i class="fa" :class="lihat?'fa-eye':'fa-eye-slash'"></i></button>
                    </div>
                </div>
                <div class="w3-row w3-section">
                    <div class="w3-col l6" v-on:click="loadCapcha">
                        <input type="text" class="w3-input w3-round w3-center cursor" style="background-color:#3f51b5;color: white;" id="mainCaptcha" v-model="rec.mainCaptcha" disabled="">
                    </div>
                    <div class="w3-col l6" style="">
                        <input class="w3-input w3-border-0" name="capcha" v-model="rec.cap" type="text" placeholder="Captcha" required>
                    </div>
                </div>
                <div class="w3-section">
                    <button class="w3-btn w3-block w3-theme w3-section w3-round-large w3-padding" type="submit" name="submit">Login</button>
                </div>
            </form>

            <div class="w3-container w3-border-top w3-padding-16 w3-white round-bottom-large">
            <input class="w3-check w3-margin-top" type="checkbox" checked="checked"> Remember me
            </div>
        </div>
    </div>
</template>
<script lang="js">
/* eslint-disable */
// import md5 from  '../../assets/doevent/md5.js';
import config from "@/conf/Config.js";
import axios from "axios";
axios.defaults.baseURL = config.apiUrl;
axios.defaults.headers.common["Authorization"] = localStorage.tsalestoken;


export default {
    head: {
        title: {
            inner: "Login",
        },
    },
    name: "Login",
    data() {
        return {
            lihat : false,
            screenHeight: 0,
            cap: {
                image: "",
                image_url: "",
            },
            rec: {
                username: "",
                password: "",
                cap: "",
                ingat: "T",
                mainCaptcha: "",
                pfunction: "setlogin"
            },
            recscounter: [],
            filters: {
                operator: "AND",
                field: [
                    {
                        key: "username",
                        type: "text",
                        name: "username",
                        filter: true,
                        width: "30px",
                        grid: true,
                    },
                ],
                find: [],
            },
        };
    },
    methods: {
        removeSpaces(string){
            return string.split(' ').join('');
        },
        async loadCapcha(){
            const obj = await axios({
                method: "post",
                url: "admin/ApiToken.php",
                data: {
                    pfunction : "getcaptcha"
                },
            });
            if (obj.data.captcha === undefined) {
                this.rec.mainCaptcha = obj.data.msg;
            }else{
                this.rec.mainCaptcha = obj.data.captcha;
            }
        },
        setLogin(e) {
            let self = this;
            if (self.rec.mainCaptcha != self.rec.cap || self.rec.cap == ""){
                // self.loadCapcha();
                self.$toast.error('Captcha Invalid');
                return false;
            }else{
                delete axios.defaults.headers.common["Authorization"];
                axios.defaults.headers.common["Authorization"] = localStorage.ctoken;
                axios.post("admin/ApiAdmin.php",self.rec).then(function (response) {
                    if (response.data.success == true) {
                        localStorage.tsalestoken = 'Bearer '+response.data.token;
                        localStorage.username = response.data.user[0]['vc_user'];
                        // localStorage.idsales = response.data.user[0]['vc_id'];
                        localStorage.tsaleslevel = response.data.user[0]['c_userlevel'];
                        // localStorage.username = response.data.user[0]['vc_user'];
                        localStorage.userall = response.data.user[0];
                        localStorage.setItem('userall', JSON.stringify(response.data.user[0]));
                        self.$toast.success('Login sukses');
                        self.$router.push('/home');
                    } else {
                        self.$toast.error(response.data.msg);
                    }
                });                
            }            
            event.preventDefault();
            return false;            
        },
        async loadToken() {
            const obj = await axios({
                method: "post",
                url: "admin/ApiToken.php",
                data: {
                    pfunction : "gettoken"
                },
            });
            localStorage.ctoken = 'Bearer '+obj.data.token;
            this.loadCapcha();
        },
    },
    mounted() {
        this.loadToken();
        this.screenHeight = window.innerHeight;
        document.getElementById("username").focus();
    },
}
</script>